
p.desc {
    margin-bottom: var(--space-sm);
}

.error_page {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    width: 100%;

    h1 {
        margin: 0 0 var(--space-sm);
        color: var(--color-contrast-lower);
    }
}
