
.tiles_img {
    object-fit: contain;
    object-position: left top;
    max-height: 6rem;
    width: 100%;
    margin: var(--space-sm) 0;
}
figure .tiles_img {
    object-position: center center;
}

.rule_list {
    margin-top: var(--space-sm);
    margin-bottom: var(--space-md);
    background-color: rgba(0, 0, 0, 0.3);
    overflow: hidden;
    border-radius: 1em;

    & > p {
        display: block;
        font-size: var(--text-lg);
        padding: var(--space-sm);
    }

    & > ul,
    & > ol {
        margin: var(--space-sm);
    }

    .rule_span {
        font-weight: bold;
        color: var(--color-contrast-higher);
    }
}
