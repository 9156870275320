.policy_container {
    &#{&} {
        &, p {
            color: #595959;
            font-size: 14px;
        }

        h1 {
            font-size: 26px;
        }

        h2 {
            font-size: 19px;
        }

        h3 {
            font-size: 19px;
        }

        h1, h2, h3 {
            color: black;
            margin-bottom: 0.5em;
        }

        h1, h2, h3, a {
            text-shadow: none;
        }

        td {
            padding: 0.5em;
        }
    }
}

.generated_class_1 {
    &#{&} {
        font-size: 26px;
    }
}

.generated_class_2 {
    &#{&} {
        color: rgb(127, 127, 127);
    }
}

.generated_class_3 {
    &#{&} {
        font-size: 15px;
    }
}

.generated_class_4 {
    &#{&} {
        line-height: 1.5;
    }
}

.generated_class_5 {
    &#{&} {
        color: rgb(89, 89, 89);
        font-size: 15px;
    }
}

.generated_class_6 {
    &#{&} {
        color: rgb(89, 89, 89);
    }
}

.generated_class_7 {
    &#{&} {
        font-size: 15px;
        color: rgb(89, 89, 89);
    }
}

.generated_class_8 {
    &#{&} {
        color: rgb(0, 58, 250);
    }
}

.generated_class_9 {
    &#{&} {
        color: rgb(0, 58, 250);
        font-size: 15px;
    }
}

.generated_class_10 {
    &#{&} {
        color: rgb(0, 0, 0);
    }
}

.generated_class_11 {
    &#{&} {
        color: rgb(0, 0, 0);
        font-size: 15px;
    }
}

.generated_class_12 {
    &#{&} {
        font-size: 15px;
        color: rgb(0, 0, 0);
    }
}

.generated_class_13 {
    &#{&} {
        font-size: 15px;
        line-height: 1.5;
    }
}

.generated_class_14 {
    &#{&} {
        font-size: 15px;
        color: rgb(0, 58, 250);
    }
}

.generated_class_15 {
    &#{&} {
        margin-left: 40px;
    }
}

.generated_class_16 {
    &#{&} {
        width: 100%;
    }
}

.generated_class_17 {
    &#{&} {
        width: 33.8274%;
        border-left: 1px solid black;
        border-right: 1px solid black;
        border-top: 1px solid black;
    }
}

.generated_class_18 {
    &#{&} {
        width: 51.4385%;
        border-top: 1px solid black;
        border-right: 1px solid black;
    }
}

.generated_class_19 {
    &#{&} {
        width: 14.9084%;
        border-right: 1px solid black;
        border-top: 1px solid black;
        text-align: center;
    }
}

.generated_class_20 {
    &#{&} {
        width: 14.9084%;
        text-align: center;
        vertical-align: middle;
        border-right: 1px solid black;
        border-top: 1px solid black;
    }
}

.generated_class_21 {
    &#{&} {
        width: 51.4385%;
        border-right: 1px solid black;
        border-top: 1px solid black;
    }
}

.generated_class_22 {
    &#{&} {
        width: 14.9084%;
        text-align: center;
        border-right: 1px solid black;
        border-top: 1px solid black;
    }
}

.generated_class_23 {
    &#{&} {
        border-left: 1px solid black;
        border-right: 1px solid black;
        border-top: 1px solid black;
        width: 33.8274%;
    }
}

.generated_class_24 {
    &#{&} {
        border-right: 1px solid black;
        border-top: 1px solid black;
        width: 51.4385%;
    }
}

.generated_class_25 {
    &#{&} {
        text-align: center;
        border-right: 1px solid black;
        border-top: 1px solid black;
        width: 14.9084%;
    }
}

.generated_class_26 {
    &#{&} {
        border-width: 1px;
        border-color: black;
        border-style: solid;
        width: 33.8274%;
    }
}

.generated_class_27 {
    &#{&} {
        border-bottom: 1px solid black;
        border-top: 1px solid black;
        border-right: 1px solid black;
        width: 51.4385%;
    }
}

.generated_class_28 {
    &#{&} {
        text-align: center;
        border-right: 1px solid black;
        border-bottom: 1px solid black;
        border-top: 1px solid black;
        width: 14.9084%;
    }
}

.generated_class_29 {
    &#{&} {
        border-left: 1px solid black;
        border-right: 1px solid black;
        border-bottom: 1px solid black;
        line-height: 1.5;
    }
}

.generated_class_30 {
    &#{&} {
        border-right: 1px solid black;
        border-bottom: 1px solid black;
        line-height: 1.5;
    }
}

.generated_class_31 {
    &#{&} {
        border-right: 1px solid black;
        border-bottom: 1px solid black;
    }
}

.generated_class_32 {
    &#{&} {
        text-align: center;
    }
}

.generated_class_33 {
    &#{&} {
        text-align: center;
        line-height: 1.5;
    }
}

.generated_class_34 {
    &#{&} {
        color: rgb(0, 58, 250);
        font-size: 15px;
    }
}

