
.title_section {
    width: 100%;
    text-align: center;

    h1, h2 {
        font-size: var(--text-lg);
        font-weight: 500;
        margin-top: var(--space-2xs);
        margin-bottom: var(--space-md);
    }
}

.card {
    margin-bottom: var(--space-xl);
    max-width: 540px;
}

.back {
    margin-bottom: var(--space-sm);
    a {
        color: var(--color-contrast-high);
    }
}
