@use "@/scss/config" as *;


.game_list {
    min-height: 40em;

    p {
        font-size: var(--text-md);
    }
}

.entries_container {
    overflow: hidden;
}

.entry_container {
    margin-bottom: var(--space-xs);

    &, .entry {
        border-radius: var(--space-xs);
    }
}

.entry {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    background-color: var(--color-contrast-low);
    font-size: var(--text-base);
    border-bottom: 0.3rem solid var(--color-contrast-between_lower_low);
    max-width: var(--max-width-xs);
    overflow: hidden;

    color: var(--color-contrast-high);
    &, &:hover {
        text-decoration: none;
    }

    &:hover {
        background-color: var(--color-contrast-below);
        border-bottom-color: var(--color-contrast-low);
    }

    &.view_more {
        background-color: var(--color-contrast-between_lower_low);
        border-bottom-color: var(--color-contrast-lower);
        color: var(--color-contrast-high);
        cursor: pointer;
        &:hover {
            background-color: var(--color-contrast-low);
            border-bottom-color: var(--color-contrast-between_lower_low);
        }
    }

    .win, .loss, .no_winner, .details, .icon {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: var(--space-xs) var(--space-sm);
    }

    .win, .loss, .no_winner {
        padding: 0;
        min-width: var(--space-md);
    }

    .win {
        background-color: hsl(125, 72%, 52%);
    }

    .loss {
        background-color: var(--color-error-dark);
    }

    .no_winner {
        background-color: var(--color-contrast-below);
    }

    .details {
        flex-grow: 1;
        padding-right: var(--space-2xs);

        p {
            font-size: var(--text-base);
            margin: 0;
        }
        p.metadata, p.date {
            color: var(--color-contrast-medium);
        }
        p.metadata {
            margin-top: var(--space-2xs);
            span.increase {
                color: var(--color-primary);
            }
            span.decrease {
                color: var(--color-error);
            }
        }
    }

    .icon {
        padding: var(--space-3xs) var(--space-xs);
        padding-top: var(--space-2xs);
        padding-left: var(--space-2xs);
        img {
            width: 3em;
            min-width: 3em;
            max-width: 3em;
            object-fit: contain;
        }
    }
}

.view_more_container {
    min-height: 5em;
}
