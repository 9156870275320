
.content {
    display: block;
    width: 100%;

    section {
        padding: 0;
        text-align: left;
    }
}
