
.button {
    position: relative;
    cursor: pointer;

    .button_extra_space {
        position: absolute;
        left: -50%;
        top: -50%;
        width: 200%;
        height: 200%;
    }
}

.popup_container {
    display: block;
    padding: 6px;
    max-width: 360px;

    .popup_content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }
}

.popup_content {
    padding: var(--space-2xs) var(--space-xs);
    font-size: var(--text-base);
    color: var(--color-contrast-low);
    background-color: var(--color-contrast-higher);
    border: 1px solid var(--color-contrast-low);
    border-radius: var(--space-xs);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
    text-align: left;
    margin-bottom: var(--space-md);
    pointer-events: auto;

    &.dark_popup_content {
        color: var(--color-contrast-high);
        background-color: var(--color-contrast-lower);
    }
}
