@use "@/scss/config" as *;

.math {
    margin-bottom: var(--space-md);
    max-width: 100%;
    overflow: hidden;
    @include max-width(sm) {
        font-size: 0.7em;
    }
}
