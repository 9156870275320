
.form {
    width: 100%;
    p.forgot_password {
        display: block;
        width: 100%;
        margin: var(--space-3xs) 0 var(--space-2xs);
        text-align: right;
        font-size: var(--text-sm);
    }
}

.login_popup {
    background-color: rgba(0, 0, 0, 0.6);
    padding: var(--space-md);
}

.login_popup_content {
    width: 100%;
    max-width: 540px;
}

