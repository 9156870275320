
.banner {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: var(--text-xl);
    text-align: left;
    margin-bottom: var(--space-sm);

    a {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        column-gap: 0.2em;
        flex-wrap: wrap;
        text-decoration: none;
    }

    .logo {
        width: 2em;
        height: 2em;
    }

    .left {
        line-height: 0;
    }

    .right {
        line-height: 1em;
        letter-spacing: 0.015em;
        color: #53B4EE;
        padding-bottom: 0.1em;
    }

    &.dark_mode .right {
        text-shadow:
                -0.05em -0.05em 0 #111,
                0.05em -0.05em 0 #111,
                -0.05em 0.05em 0 #111,
                0.05em 0.05em 0 #111;
    }
}
