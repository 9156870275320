
.container {
    position: relative;
    width: 100%;

    .input {
        margin: 0;
        padding-right: 2.4em;

        &::-ms-reveal,
        &::-ms-clear {
            visibility: hidden;
            pointer-events: none;
            display: none;
        }
    }
    .visibility_toggle {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        padding: 0 0.8em;
        cursor: pointer;
        opacity: 0.6;

        svg {
            width: 1.8em;
            height: auto;
        }
    }
}
