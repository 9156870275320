.policy_container {
    &#{&} {
        &, p {
            color: #595959;
            font-size: 14px;
        }

        h1 {
            font-size: 26px;
        }

        h2 {
            font-size: 19px;
        }

        h3 {
            font-size: 19px;
        }

        h1, h2, h3 {
            color: black;
            margin-bottom: 0.5em;
        }

        h1, h2, h3, a {
            text-shadow: none;
        }

        td {
            padding: 0.5em;
        }
    }
}

.generated_class_1 {
    &#{&} {
        text-align: left;
    }
}

.generated_class_2 {
    &#{&} {
        line-height: 1.5;
    }
}

.generated_class_3 {
    &#{&} {
        font-size: 19px;
    }
}

.generated_class_4 {
    &#{&} {
        line-height: 1.1;
    }
}

.generated_class_5 {
    &#{&} {
        line-height: 115%;
    }
}

.generated_class_6 {
    &#{&} {
        line-height: 1;
    }
}

.generated_class_7 {
    &#{&} {
        font-size: 11.0pt;
        line-height: 115%;
        color: #595959;
    }
}

.generated_class_8 {
    &#{&} {
        font-size: 11.0pt;
        line-height: 115%;
        color: #595959;
    }
}

.generated_class_9 {
    &#{&} {
        font-size: 15px;
    }
}

.generated_class_10 {
    &#{&} {
        color: rgb(89, 89, 89);
    }
}

.generated_class_11 {
    &#{&} {
        color: rgb(0, 58, 250);
    }
}

.generated_class_12 {
    &#{&} {
        color: rgb(0, 58, 250);
        font-size: 15px;
    }
}

.generated_class_13 {
    &#{&} {
        font-size: 15px;
    }
}

.generated_class_14 {
    &#{&} {
        text-align: left;
        line-height: 1.5;
    }
}

.generated_class_15 {
    &#{&} {
        line-height: 115%;
        font-size: 19px;
    }
}

.generated_class_16 {
    &#{&} {
        color: rgb(89, 89, 89);
        font-size: 11pt;
    }
}

.generated_class_17 {
    &#{&} {
        color: rgb(89, 89, 89);
        font-size: 14.6667px;
    }
}

.generated_class_18 {
    &#{&} {
        text-align: justify;
        line-height: 115%;
    }
}

.generated_class_19 {
    &#{&} {
        line-height: 17.25px;
    }
}

.generated_class_20 {
    &#{&} {
        line-height: 1.5;
        text-align: left;
    }
}

.generated_class_21 {
    &#{&} {
        font-size: 11pt;
        line-height: 16.8667px;
        color: rgb(89, 89, 89);
    }
}

.generated_class_22 {
    &#{&} {
        line-height: 1.1;
        text-align: left;
    }
}

.generated_class_23 {
    &#{&} {
        line-height: 24.5333px;
        font-size: 19px;
    }
}

.generated_class_24 {
    &#{&} {
        text-align: justify;
        line-height: 115%;
    }
}

.generated_class_25 {
    &#{&} {
        text-align: left;
        line-height: 1;
    }
}

.generated_class_26 {
    &#{&} {
        line-height: 1.5;
        margin-left: 20px;
    }
}

.generated_class_27 {
    &#{&} {
        font-size: 15px;
        line-height: 115%;
        color: rgb(89, 89, 89);
    }
}

.generated_class_28 {
    &#{&} {
        line-height: 115%;
        color: rgb(89, 89, 89);
    }
}

.generated_class_29 {
    &#{&} {
        color: rgb(10, 54, 90);
        text-align: left;
    }
}

.generated_class_30 {
    &#{&} {
        text-align: justify;
        line-height: 1.5;
    }
}

.generated_class_31 {
    &#{&} {
        font-size: 15px;
        line-height: 1.5;
    }
}

.generated_class_32 {
    &#{&} {
        text-align: justify;
        line-height: 1;
    }
}

.generated_class_33 {
    &#{&} {
        font-size: 15px;
        font-variant-ligatures: normal;
        font-variant-caps: normal;
        font-weight: 400;
        letter-spacing: normal;
        text-align: justify;
        text-indent: -29.4px;
        text-transform: none;
        white-space: normal;
        background-color: rgb(255, 255, 255);
        text-decoration-style: initial;
        text-decoration-color: initial;
        color: rgb(89, 89, 89);
    }
}

.generated_class_34 {
    &#{&} {
        line-height: 16.8667px;
        color: rgb(89, 89, 89);
    }
}

.generated_class_35 {
    &#{&} {
        font-style: normal;
        font-variant-ligatures: normal;
        font-variant-caps: normal;
        font-weight: 400;
        letter-spacing: normal;
        text-align: justify;
        text-indent: -29.4px;
        text-transform: none;
        white-space: normal;
        word-spacing: 0;
        background-color: rgb(255, 255, 255);
        text-decoration-style: initial;
        text-decoration-color: initial;
        color: rgb(89, 89, 89);
    }
}

.generated_class_36 {
    &#{&} {
        font-size: 11pt;
        line-height: 16.8667px;
        color: rgb(89, 89, 89);
        font-style: normal;
        font-variant-ligatures: normal;
        font-variant-caps: normal;
        font-weight: 400;
        letter-spacing: normal;
        text-align: justify;
        text-indent: -29.4px;
        text-transform: none;
        white-space: normal;
        word-spacing: 0;
        background-color: rgb(255, 255, 255);
        text-decoration-style: initial;
        text-decoration-color: initial;
    }
}

.generated_class_37 {
    &#{&} {
        font-size: medium;
        text-align: left;
    }
}

.generated_class_38 {
    &#{&} {
        font-size: 14px;
    }
}

.generated_class_39 {
    &#{&} {
        font-size: 14.6667px;
    }
}

.generated_class_40 {
    &#{&} {
        font-size: 19px;
        color: rgb(0, 0, 0);
    }
}

.generated_class_41 {
    &#{&} {
        font-size: 11pt;
        color: rgb(89, 89, 89);
        text-transform: uppercase;
    }
}

.generated_class_42 {
    &#{&} {
        line-height: 115%;
    }
}

