@use "@/scss/config" as *;

.values_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: var(--space-md);

    .values {
        display: block;
        background-color: var(--color-bg-dark);

        div {
            font-size: var(--text-lg);
            margin: var(--space-sm);
            span {
                font-weight: bold;
                color: var(--color-contrast-higher);
            }
        }
    }
}

article.planning section {
    .roadmap_content, .roadmap_section {
        padding: 0;
        text-align: left;
        width: 100%;
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;

        h2 {
            margin-top: var(--space-md);
            margin-bottom: var(--space-md);
        }
    }

    .roadmap_section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0 var(--space-md);

        .roadmap_title {
            display: flex;
            flex-direction: row-reverse;
            border-radius: var(--space-sm);
            overflow: hidden;
            background-color: var(--color-bg-lighter);
            border-bottom: var(--space-3xs) solid var(--color-bg-dark);
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
            margin-bottom: var(--space-sm);

            width: 100%;
            @include min-width(sm) {
                max-width: 30em;
            }

            .title_img {
                background-color: var(--color-bg-darker);

                width: 16em;
                max-width: 10em;
                @include min-width(sm) {
                    max-width: 16em;
                }

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }

            .title_text {
                flex-grow: 1;
                font-size: var(--text-lg);
                font-weight: bold;
                color: var(--color-contrast-higher);
                padding: var(--space-sm);
            }
        }
        .roadmap_desc {
            padding: var(--space-sm);
            padding-left: 0;
        }
    }
}

@include min-width(sm) {
    .shift_left {
        margin-right: var(--space-3xl);
    }
    .shift_right {
        margin-left: var(--space-3xl);
    }
}
