
div.card {
    color: var(--color-contrast-high);
    background-color: var(--color-bg);

    a {
        color: var(--color-rosette);
    }

    h1, h2 {
        margin-top: var(--space-md);
        color: var(--color-contrast-higher);
    }

    input {
        box-shadow: var(--shadow-sm);
    }

    input[type=text], input[type=password], input[type=email] {
        color: var(--color-contrast-higher);
        background-color: var(--color-contrast-lower);
        border: 1px solid var(--color-contrast-low);

        &:focus-visible {
            border-color: transparent;
        }
    }

    input[type=checkbox] {
        border-color: var(--color-contrast-high);

        &:checked {
            background-color: var(--color-rosette-dark);
        }
    }

    button[type=submit], .button {
        color: var(--color-contrast-highest);
        background-color: var(--color-rosette-dark);
        border-bottom-color: var(--color-rosette-darker);
        background-image: none;

        &:hover {
            color: var(--color-contrast-highest);
            background-color: var(--color-rosette);
            background-image: none;
        }
        &:active {
            color: var(--color-contrast-highest);
            background-color: var(--color-rosette-dark);
            background-image: none;
        }
        &:disabled {
            color: var(--color-contrast-low);
            background-color: var(--color-contrast-medium);
            border-bottom-color: var(--color-contrast-low);
            background-image: none;
        }
    }
}
