
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;

    position: absolute;
    &.fixed {
        position: fixed;
        .contents {
            overflow-y: scroll;
        }
    }
}
