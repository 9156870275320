
a.box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: var(--space-xs) var(--space-md);
    background-color: var(--color-contrast-highest);
    border: 1px solid #747775;
    border-radius: 0.6em;
    box-shadow: var(--shadow-sm);
    font-size: var(--text-base);

    span {
        color: var(--color-contrast-low);
        font-weight: 500;
        text-decoration: none;
        cursor: pointer;
    }

    &:hover, &:active {
        text-decoration: none;
        background-color: var(--color-contrast-higher);
    }

    img {
        height: 1.5em;
        width: 1.5em;
        margin-right: var(--space-sm);
    }
}
