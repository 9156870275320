@use "@/scss/config" as *;

.play_button {
  display: block;
  text-align: center;
  margin-bottom: var(--space-2xl);
}

.icon {
  display: inline-block;
  max-width: 1.8em;
  max-height: 1.8em;
  vertical-align: middle;
}

.icon.h1_icon {
  display: none;
  max-width: 4em;
  max-height: 4em;

  @include min-width(sm) {
    display: block;
  }
}

h3 {
  .icon {
    max-width: 2em;
    max-height: 2em;
    vertical-align: baseline;
    transform: translateY(0.3em);
  }
}

.list {
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 1em;
  padding: 0.6em 1.8em 0 1em;
  font-size: var(--text-base);

  li {
    margin-left: 1em;
  }
}
