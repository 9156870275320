
.container {
    position: absolute;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    pointer-events: none;

    &.visible {
        pointer-events: auto;
    }
}
