
.not_found {
  display: block;
  text-align: center;
  margin-bottom: var(--space-2xl);

  h1 {
    margin-bottom: var(--space-md);
  }
}
