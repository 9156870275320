
.button {
    &#{&} {
        background-color: #131314;
        border: 1px solid #8e918f;

        span {
            color: #e3e3e3;
        }

        &:hover {
            background-color: #262626;
        }
        &:active {
            background-color: #131314;
        }
    }
}
