
        .policy_container {
            &#{&} {
                &, p {
                    color: #595959;
                    font-size: 14px;
                }

                h1 {
                    font-size: 26px;
                }

                h2 {
                    font-size: 19px;
                }

                h3 {
                    font-size: 19px;
                }

                h1, h2, h3 {
                    color: black;
                    margin-bottom: 0.5em;
                }

                h1, h2, h3, a {
                    text-shadow: none;
                }

                td {
                    padding: 0.5em;
                }
            }
        }

.generated_class_1 {
    &#{&} {
        font-size: 26px;
    }
}

.generated_class_2 {
    &#{&} {
        color: rgb(127, 127, 127);
    }
}

.generated_class_3 {
    &#{&} {
        font-size: 15px;
    }
}

.generated_class_4 {
    &#{&} {
        line-height: 1.5;
    }
}

.generated_class_5 {
    &#{&} {
        color: rgb(89, 89, 89);
        font-size: 15px;
    }
}

.generated_class_6 {
    &#{&} {
        color: rgb(0, 58, 250);
        font-size: 15px;
    }
}

.generated_class_7 {
    &#{&} {
        color: rgb(0, 0, 0);
        font-size: 15px;
    }
}

.generated_class_8 {
    &#{&} {
        font-size: 15px;
        color: rgb(89, 89, 89);
    }
}

.generated_class_9 {
    &#{&} {
        color: rgb(0, 0, 0);
    }
}

.generated_class_10 {
    &#{&} {
        font-size: 15px;
        line-height: 1.5;
    }
}

.generated_class_11 {
    &#{&} {
        width: 100%;
        border: 1px solid rgb(230, 230, 230);
        margin: 0 0 10px;
        border-radius: 3px;
        text-align: left;
    }
}

.generated_class_12 {
    &#{&} {
        padding: 8px 13px;
        border-bottom: 1px solid #e6e6e6;
    }
}

.generated_class_13 {
    &#{&} {
        font-size: 12px;
        line-height: 1.67;
        margin: 0 0 8px;
        vertical-align: top;
    }
}

.generated_class_14 {
    &#{&} {
        text-align: right;
        color: #19243c;
        min-width: 80px;
    }
}

.generated_class_15 {
    &#{&} {
        display: inline-block;
        margin-left: 5px;
    }
}

.generated_class_16 {
    &#{&} {
        color: #8b93a7;
        word-break: break-all;
    }
}

.generated_class_17 {
    &#{&} {
        font-size: 12px;
        line-height: 1.67;
        margin: 0;
        vertical-align: top;
    }
}

.generated_class_18 {
    &#{&} {
        text-align: right;
        color: rgb(25, 36, 60);
        min-width: 80px;
    }
}

.generated_class_19 {
    &#{&} {
        color: rgb(0, 58, 250);
    }
}

.generated_class_20 {
    &#{&} {
        width: 100%;
        border: 1px solid #e6e6e6;
        margin: 0 0 10px;
        border-radius: 3px;
    }
}

.generated_class_21 {
    &#{&} {
        color: rgb(89, 89, 89);
    }
}

.generated_class_22 {
    &#{&} {
        font-size: 15px;
        color: rgb(89, 89, 89);
    }
}

.generated_class_23 {
    &#{&} {
        font-size: 15px;
        color: rgb(0, 0, 0);
    }
}

