
.tagline_section {
  text-align: center;
  h1 {
    font-size: var(--text-2xl);
    margin-bottom: var(--space-md);
    text-shadow: none;

    span.gradient_primary,
    span.gradient_accent {
      white-space: nowrap;
      background-size: 100%;
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      text-fill-color: transparent;
    }

    span.gradient_primary {
      color: var(--color-primary);
      background-image: linear-gradient(
                      180deg,
                      var(--color-primary-lighter) 0%,
                      var(--color-primary-light) 10%,
                      var(--color-primary) 70%,
                      var(--color-primary-dark) 90%,
                      var(--color-primary-darker) 100%
      );
    }

    span.gradient_accent {
      color: var(--color-accent);
      background-image: linear-gradient(
                      180deg,
                      var(--color-accent-lighter) 0%,
                      var(--color-accent-light) 10%,
                      var(--color-accent) 70%,
                      var(--color-accent-dark) 90%,
                      var(--color-accent-darker) 100%
      );
    }
  }

  img {
    max-height: 24em;
  }
}

.mission {
  ol {
    font-size: var(--text-md);
    li {
      padding-top: var(--space-sm);
    }
  }
}