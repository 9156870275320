
p.or {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: 0;

    .text {
        display: block;
        padding: 0 var(--space-sm);
        color: var(--color-contrast-medium);
        font-weight: bold;
    }
    .line {
        flex-grow: 1;
        background-color: var(--color-contrast-high);
        height: 1px;
    }
}
