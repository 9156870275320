@use "@/scss/config" as *;

.play_buttons {
    display: flex;
    flex-direction: column;
    gap: var(--space-md);
    max-width: 560px;
    width: 100%;

    .play_buttons_bottom_row {
        display: flex;
        flex-direction: column;
        padding: 0 var(--space-xs);
        gap: var(--space-sm);
        width: 100%;

        .bottom_row_button {
            width: 100%;
        }

        @include min-width(sm) {
            flex-direction: row;
        }
    }
}
