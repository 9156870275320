
.title_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: var(--space-sm) 0;

    img {
        height: 5em;
        width: 5em;
        vertical-align: middle;
        margin-right: var(--space-xs);
    }

    .title {
        h1, p {
            margin: 0;
        }
        p {
            color: var(--color-contrast-high);
        }
    }
}

.play {
    margin-top: var(--space-sm);
    padding: var(--space-md);
}
